<template>
  <div
    class="file is-large is-boxed has-name is-fullwidth"
    :class="{ 'is-success': dragoverClass, 'is-success': localFile !== '' }"
  >
    <label
      @dragover.prevent="dragover"
      @dragleave.prevent="dragleave"
      @drop.prevent="drop"
      class="file-label"
    >
      <input
        :disabled="fieldDisabled"
        class="file-input"
        id="file-input"
        type="file"
        name="file"
        @change="onChange"
        :accept="fileType"
      />
      <span class="file-cta">
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        <span class="file-label">{{ texts.PART_ONE }}</span>
        <span class="file-label">{{ texts.PART_TWO }}</span>
      </span>
      <span
        class="file-name"
        :class="{ 'set-primary-border': localFile !== '' }"
      >
        {{ localFile.name }}
      </span>
    </label>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import environment from "@/environment";

export default {
  name: "FileInput",
  props: ["fieldDisabled", "fileType"],
  data() {
    return {
      texts: environment.language.COMPONENTS.FILE_INPUT,
      localFile: "",
      dragoverClass: false,
    };
  },
  methods: {
    ...mapActions(["ADD_SKUS_FILE"]),
    onChange(event) {
      this.localFile = event.target.files[0];
      this.ADD_SKUS_FILE(this.localFile);
    },
    dragover(event) {
      if (!this.fieldDisabled) {
        event.preventDefault();
        this.dragoverClass = true;
      }
    },
    dragleave() {
      if (!this.fieldDisabled) {
        this.dragoverClass = false;
      }
    },
    drop(event) {
      if (!this.fieldDisabled) {
        this.dragoverClass = false;
        this.localFile = event.dataTransfer.files[0];
        const fileType = this.localFile.name.split(".").pop();
        if (!["xlsx", "xls"].includes(fileType)) {
          console.log(`CANNOT LOAD FILE of TYPE: ${fileType}`);
          this.localFile = "";
          this.ADD_SKUS_FILE(this.localFile);
          return;
        }
        this.ADD_SKUS_FILE(this.localFile);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/main.scss";

.set-primary-border {
  color: $success;
  border-color: $success;
}
</style>
