<template>
  <div>
    <div class="block">
      <progress class="progress is-large" :value="progress" max="100">
        {{ progress }}%
      </progress>
    </div>

    <div v-if="conversionModalVisible">
      <Modal @close="closeConversionModal">
        <template v-slot:header> {{ texts.CONVERSION_ERRORS }} </template>
        <template v-slot:body>
          <ul v-for="error in errors" :key="error">
            <li>{{ error }}</li>
          </ul>
        </template>
        <template v-slot:footer>
          <button class="button is-danger" @click="closeConversionModal">
            {{ texts.BTN_CLOSE }}
          </button>
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import Modal from "./Modal.vue";
import ApiService from "@/services/apiService.js";
import environment from "@/environment";
import getErrorMessage from "@/utils/getErrorMessage";

export default {
  name: "ProgressBar",
  props: [
    "showConvertButton",
    "showDownloadButton",
    "showProgressBar",
    "disableFields",
  ],
  components: {
    Modal,
  },
  data() {
    return {
      groupId: null,
      progress: 0.0,
      completed: false,
      conversionModalVisible: false,
      errors: [],
      fileReadyForDownload: false,
      fileFinishedProcessing: false,
      texts: environment.language.COMPONENTS.PROGRESS_BAR,
    };
  },
  mounted: function () {
    this.loadGroupId();
    this.getGroupProgress();
    this.progressPolling();
  },
  methods: {
    loadGroupId() {
      this.groupId = sessionStorage.groupId;
    },
    progressPolling() {
      const interval = setInterval(() => this.getGroupProgress(), 3000);
      return () => clearInterval(interval);
    },
    getGroupProgress() {
      if (!this.completed) {
        this.updateProgress();
      }
    },
    updateFileProcessingStatus() {
      ApiService.post(`/get-conversion-progress/${this.groupId}`)
        .then((res) => {
          const responseData = res.data;
          this.progress = responseData.progress_percentage;
          const error_status_api = [
            "CONVERSION_ERROR",
            "SELLER_UNDER_REVIEW_ERROR",
          ];

          if (error_status_api.includes(responseData.status)) {
            this.completed = true;
            this.conversionModalVisible = true;
            this.errors.push(getErrorMessage(res));
            this.$emit("update:showConvertButton", false);
          }
          if (responseData.progress_percentage >= 100) {
            this.fileFinishedProcessing = true;
            this.progress = 99;
          }
        })
        .catch((error) => console.log(error));
    },
    updateFileReadyStatus() {
      ApiService.post(`/check-file-availability/${this.groupId}`)
        .then((res) => {
          console.log(res);
          this.progress = 100;
          this.completed = true;
          this.$emit("update:showProgressBar", false);
          this.$emit("update:showConvertButton", false);
          this.$emit("update:showDownloadButton", true);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateProgress() {
      if (!this.fileFinishedProcessing) {
        this.updateFileProcessingStatus();
      } else {
        this.updateFileReadyStatus();
      }
    },

    showConversionModal() {
      this.conversionModalVisible = true;
    },
    closeConversionModal() {
      this.conversionModalVisible = false;
      this.errors = [];
      this.$emit("update:disableFields", false);
      this.$emit("update:showProgressBar", false);
      this.$emit("update:showConvertButton", true);
      this.$emit("update:showDownloadButton", false);
    },
  },
};
</script>
