import environment from "@/environment";

const texts = environment.language.COMPONENTS.PRICE_ADJUSTMENT;

const valueAdjustmentConfig = {
  name: "valueAdjustment",
  criteria: {
    increaseText: texts.VALUE_INCREASE_TEXT,
    reduceText: texts.VALUE_REDUCE_TEXT,
  },
  icon: {
    name: "fas fa-dollar-sign",
    class: "icon is-small is-left",
    controlIconPosition: "has-icons-left",
  },
  placeholder: texts.VALUE_PLACEHOLDER,
  checkboxText: texts.VALUE_CHECKBOX,
  tooltipText: texts.VALUE_DESCRIBE,
};

export default valueAdjustmentConfig;
