<template>
  <div class="block mx-0">
    <label
      class="field is-flex is-justify-content-flex-start checkbox tooltip"
      :data-text="config.tooltipText"
    >
      <div class="field">
        <input
          :disabled="fieldDisabled"
          :id="config.name"
          type="checkbox"
          :name="config.name"
          class="switch is-danger"
          @change="toggleFields()"
        />
        <label :for="config.name">
          {{ config.checkboxText }}
        </label>
      </div>
    </label>

    <div class="field has-addons">
      <p class="control">
        <span class="select">
          <select
            :disabled="inputDisabled || fieldDisabled"
            v-model="criteria"
            :id="config.name"
            @change="change()"
          >
            <option value="INCREASE">
              {{ config.criteria.increaseText }}
            </option>
            <option value="DECREASE">
              {{ config.criteria.reduceText }}
            </option>
          </select>
        </span>
      </p>
      <p class="control" :class="config.icon.controlIconPosition">
        <input
          :disabled="inputDisabled || fieldDisabled"
          class="input"
          required="required"
          type="number"
          step="any"
          min="0"
          oninput="validity.valid||(value='')"
          :placeholder="config.placeholder"
          v-model="factor"
          @input="change()"
        />
        <span :class="iconClass">
          <i :class="iconName"></i>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceAdjustment",
  props: ["config", "fieldDisabled"],
  data() {
    return {
      inputDisabled: true,
      criteria: "DECREASE",
      factor: null,
      iconClass: this.config.icon.class,
      iconName: this.config.icon.name,
    };
  },
  watch: {
    factor: function (value) {
      this.factor = parseFloat(value);
    },
  },
  methods: {
    toggleFields() {
      this.inputDisabled = !this.inputDisabled;
      if (this.inputDisabled) {
        this.factor = null;
        this.criteria = "DECREASE";
        this.$emit(`update:${this.config.name}`, null);
      }
    },
    change() {
      this.$emit(`update:${this.config.name}`, this.convertFactor());
    },
    convertFactor() {
      return this.criteria === "INCREASE" ? this.factor : `-${this.factor}`;
    },
  },
};
</script>

<style scoped>
/* Disable number spinners in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Disable number spinners in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tooltip {
  position: relative; /* making the .tooltip span a container for the tooltip text */
}

.tooltip:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;
  z-index: 10;
  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 50%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 400px;
  padding: 10px;
  border-radius: 10px;
  background: rgb(136, 136, 136);
  color: #fff;
  text-align: center;

  display: none; /* hide by default */
}

.tooltip:hover:before {
  display: block;
}
.switch[type="checkbox"].is-danger:checked + label::before,
.switch[type="checkbox"].is-danger:checked + label::before {
  background: #ee4d2d;
}
</style>
