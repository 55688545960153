<template>
  <footer class="footer is-small">
    <div class="content has-text-centered">
      {{ texts.TEXT }}
      <a v-if="shouldPresentEmail" :href="email" target="_blank">
        {{ texts.ANCHOR_EMAIL }}
      </a>
      <p></p>
    </div>
  </footer>
</template>

<script>
import environment from "@/environment";

export default {
  name: "FooterSkus",
  data() {
    return {
      texts: environment.language.COMPONENTS.FOOTER_SKUS,
      email: this.mountEmailLink(),
    };
  },
  methods: {
    shouldPresentEmail() {
      return environment.language.COMPONENTS.FOOTER_SKUS.EMAIL === null
        ? false
        : true;
    },
    mountEmailLink() {
      return `mailto:${environment.language.COMPONENTS.FOOTER_SKUS.EMAIL}`;
    },
  },
};
</script>
