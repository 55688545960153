import i18next from "i18next";

export default async (language) => {
  const config = {
    lng: language,
    debug: process.env.NODE_ENV == "production" ? false : true,
    resources: {},
  };

  config.resources[language] = {
    translation: require(`../languages/${language}.json`).RESPONSE_ERRORS,
  };

  await i18next.init(config);
};
