const findCurrentLanguage = () => {
  return process.env.VUE_APP_LANGUAGE
    ? require(`./languages/${process.env.VUE_APP_LANGUAGE}.json`)
    : require(`./languages/pt_br.json`);
};

const findCountry = () => {
  switch (process.env.VUE_APP_LANGUAGE) {
    case "es_mx":
      return "MX";

    case "es_ar":
      return "AR";

    case "es_cl":
      return "CL";

    case "es_co":
      return "CO";

    default:
      return "BR";
  }
};

const environment = {
  apiURL: process.env.VUE_APP_API_URL,
  instructionsVideoURL: process.env.VUE_APP_INSTRUCTIONS_VIDEO_URL,
  categoriesTreeURL: process.env.VUE_APP_CATEGORIES_TREE_URL,
  shopeeUploadInstructionsVideoURL:
    process.env.VUE_APP_SHOPEE_UPLOAD_INSTRUCTIONS_VIDEO_URL,
  language: findCurrentLanguage(),
  country: findCountry(),
};

export default environment;
