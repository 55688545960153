import Vue from "vue";
import App from "./App.vue";

import i18nLoad from "./utils/i18nLoad";

import store from "./store";
import router from "./router";

import "./assets/main.scss";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

Vue.config.productionTip = false;

i18nLoad(process.env.VUE_APP_LANGUAGE || "pt_br");

new Vue({
  store,
  render: (h) => h(App),
  router,
}).$mount("#app");
