<template>
  <div id="app" class="container">
    <Header />
    <div class="box">
      <Instructions />
      <router-view />
    </div>
    <FooterSkus />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Instructions from "./components/Instructions.vue";
import FooterSkus from "./components/FooterSkus.vue";
import environment from "@/environment";

export default {
  name: "App",
  components: {
    Header,
    Instructions,
    FooterSkus,
  },
  data() {
    return {
      shouldPrevent: true,
    };
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventPageToUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventPageToUnload);
  },
  created() {
    document.title = environment.language.PAGE_TITLE;
  },
  methods: {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    preventPageToUnload(e) {
      if (!this.shouldPrevent) return;
      e.preventDefault();
      e.returnValue = environment.language.COMPONENTS.APP.MESSAGE_EXIT;
      return environment.language.COMPONENTS.APP.MESSAGE_EXIT;
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  transition: all 0.3s;
}
#app {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 20px;
}
.box {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
</style>
