import Vue from "vue";
import VueRouter from "vue-router";

import Form from "@/components/Form.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Form,
    props: {
      convertFileURL: "/convert-file",
      fileType: ".xlsx",
    },
  },
  {
    path: "/tn",
    component: Form,
    props: {
      convertFileURL: "/convert-file-tn",
      showTiendaNubeInput: true,
      fileType: ".csv",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
