<template>
  <h1 class="title is-9">{{ texts.TITLE }}</h1>
</template>

<script>
import environment from "../environment";

export default {
  name: "Header",
  data() {
    return {
      texts: environment.language.COMPONENTS.HEADER,
    };
  },
};
</script>
