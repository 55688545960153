import i18next from "i18next";

export default (response) => {
  try {
    const { code, data } = response.data.error;
    return data ? i18next.t(code, data) : i18next.t(code, {});
  } catch {
    return i18next.t("InternalError");
  }
};
