<template>
  <div>
    <form class="form" name="convertform" @submit.prevent="sendFile">
      <file-input
        :fieldDisabled="fieldDisabled"
        :fileType="fileType"
        :key="fileInputComponentKey"
      />

      <hr />

      <store-url
        :storeUrl.sync="storeUrl"
        :fieldDisabled="fieldDisabled"
        :key="storeUrlComponentKey"
        :label="storeTexts.SHOPEE_INPUT_TEXT"
      />

      <store-url
        v-if="showTiendaNubeInput"
        :storeUrl.sync="tiendaNubeUrl"
        :fieldDisabled="fieldDisabled"
        :key="tiendaNubeUrlComponentKey"
        :label="storeTexts.TIENDA_NUBE_INPUT_TEXT"
      />

      <div v-if="isDownloading">
        <button
          class="button is-success is-large is-rounded"
          :class="buttonLoading"
          :disabled="downloadButtonDisabled"
          @click="downloadShopeeExcel"
        >
          <span class="icon">
            <i class="fas fa-download"></i>
          </span>
          <span
            ><strong>{{ texts.DOWNLOAD_TEXT }}</strong></span
          >
        </button>
      </div>

      <div v-if="showProgressBar" class="block" style="margin: 30px 0 10px 0">
        <ProgressBar
          :showConvertButton.sync="showConvertButton"
          :showDownloadButton.sync="isDownloading"
          :showProgressBar.sync="showProgressBar"
          :disableFields.sync="fieldDisabled"
        />
      </div>

      <hr />

      <price-adjustment
        :config="valueConfig"
        :fieldDisabled="fieldDisabled"
        :valueAdjustment.sync="valueAdjustmentFactor"
        :key="valueAdjustmentComponentKey"
      />

      <price-adjustment
        :config="percentageConfig"
        :fieldDisabled="fieldDisabled"
        :percentageAdjustment.sync="percentageAdjustmentFactor"
        :key="percentageAdjustmentComponentKey"
      />

      <quantity-adjustment
        v-if="quantityAdjustmentConfig.enabled"
        :config="quantityAdjustmentConfig"
        :fieldDisabled="fieldDisabled"
        :quantityPercentageAdjustmentFactor.sync="
          quantityPercentageAdjustmentFactor
        "
        :key="quantityAdjustmentComponentKey"
      />

      <logistic-channels
        v-model="selectedChannel"
        :options="channels"
        @loaded="updateChannels"
      />

      <hr />

      <div
        v-if="showConvertButton && !showProgressBar"
        class="control button-container"
      >
        <button
          class="button is-large is-rounded"
          :class="[
            buttonLoading,
            {
              'is-primary': !isFileUploaded,
              'is-success': isFileUploaded,
            },
          ]"
          :disabled="fieldDisabled"
        >
          <span class="icon">
            <i class="fas fa-sync"></i>
          </span>
          <span
            ><strong>{{ texts.CONVERT_TEXT }}</strong></span
          >
        </button>
      </div>
    </form>

    <div v-if="isModalVisible">
      <Modal @close="closeModal">
        <template v-slot:header>
          {{ texts.ERROR_TITLE }}
        </template>
        <template v-slot:body>
          <ul v-for="error in errors" :key="error">
            <li>{{ error }}</li>
          </ul>
        </template>
        <template v-slot:footer>
          <button class="button is-danger" @click="closeModal">
            {{ texts.MODAL_CLOSE }}
          </button>
        </template>
      </Modal>
    </div>

    <div v-if="isDisclaimerModalVisible">
      <Modal @close="closeDisclaimerModal">
        <template v-slot:header><span></span></template>
        <template v-slot:body>
          <disclaimer :disclaimerCheck.sync="disclaimerCheck" />
        </template>
        <template v-slot:footer>
          <p class="buttons is-centered">
            <button
              class="button is-primary"
              v-if="disclaimerCheck"
              @click="acceptDisclaimerModal"
            >
              {{ texts.BTN_CONVERT }}
            </button>
            <button
              class="button is-primary"
              v-else
              @click="closeDisclaimerModal"
            >
              {{ texts.BTN_CLOSE }}
            </button>
          </p>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Modal from "./Modal.vue";
import StoreUrl from "./StoreUrl.vue";
import PriceAdjustment from "./price-adjustment/PriceAdjustment.vue";
import percentageAdjustmentConfig from "./price-adjustment/percentageAdjustmentConfig.js";
import valueAdjustmentConfig from "./price-adjustment/valueAdjustmentConfig.js";
import FileInput from "./FileInput.vue";
import ProgressBar from "./ProgressBar.vue";
import Disclaimer from "./Disclaimer.vue";
import ApiService from "@/services/apiService.js";
import getErrorMessage from "@/utils/getErrorMessage";
import environment from "@/environment.js";
import LogisticChannels from "./logistic_channels/LogisticChannels.vue";
import QuantityAdjustment from "./quantity-adjustment/QuantityAdjustment.vue";
import quantityAdjustmentConfig from "./quantity-adjustment/quantityAdjustmentConfig";

export default {
  name: "Form",
  props: ["convertFileURL", "showTiendaNubeInput", "fileType"],
  components: {
    Modal,
    StoreUrl,
    PriceAdjustment,
    FileInput,
    ProgressBar,
    Disclaimer,
    LogisticChannels,
    QuantityAdjustment,
  },
  data() {
    return {
      selectedChannel: "",
      channels: [],
      storeTexts: environment.language.COMPONENTS.STORE,
      texts: environment.language.COMPONENTS.FORM,
      isModalVisible: false,
      errors: [],
      storeUrl: null,
      storeUrlComponentKey: 10,
      fileInputComponentKey: 20,
      valueConfig: valueAdjustmentConfig,
      valueAdjustmentFactor: null,
      valueAdjustmentComponentKey: 30,
      percentageConfig: percentageAdjustmentConfig,
      percentageAdjustmentFactor: null,
      percentageAdjustmentComponentKey: 40,
      quantityAdjustmentConfig,
      quantityPercentageAdjustmentFactor: null,
      quantityAdjustmentComponentKey: 60,
      tiendaNubeUrl: null,
      tiendaNubeUrlComponentKey: 70,
      showConvertButton: true,
      showDownloadButton: false,
      downloadButtonDisabled: false,
      buttonLoading: { "is-loading": false },
      fieldDisabled: false,
      showProgressBar: false,
      isDisclaimerModalVisible: false,
      disclaimerCheck: false,
    };
  },
  computed: {
    ...mapGetters(["getFile"]),
    isFileUploaded() {
      return this.getFile !== "";
    },
    isDownloading: {
      get() {
        return this.showDownloadButton;
      },
      set(newValue) {
        if (newValue) this.downloadShopeeExcel();
        this.showDownloadButton = newValue;
      },
    },
  },
  methods: {
    ...mapActions(["ADD_SKUS_FILE"]),

    updateChannels(data) {
      this.channels = data;
    },
    forceComponentsReRender() {
      this.storeUrlComponentKey += 1;
      this.fileInputComponentKey += 1;
      this.valueAdjustmentComponentKey += 1;
      this.percentageAdjustmentComponentKey += 1;
      this.quantityAdjustmentComponentKey += 1;
      this.tiendaNubeUrlComponentKey += 1;

      this.ADD_SKUS_FILE("");
      this.storeUrl = null;
      this.valueAdjustmentFactor = null;
      this.percentageAdjustmentFactor = null;
      this.quantityPercentageAdjustmentFactor = null;
      this.tiendaNubeUrl = null;
    },
    sendFile() {
      if (!this.disclaimerCheck) {
        this.showDisclaimerModal();
      } else {
        if (this.hasValidationError()) {
          this.showModal();
        } else {
          this.buttonLoading = { "is-loading": true };
          this.fieldDisabled = true;
          this.submitFileForConversion();
        }
      }
    },
    hasValidationError() {
      const phrases = environment.language.COMPONENTS.FORM;

      if (this.getFile === "") this.errors.push(`- ${phrases.ERROR_SELECT}`);

      if (this.selectedChannel === "")
        this.errors.push(`- ${phrases.ERROR_UNSELECTED_CHANNEL}`);

      if (this.showTiendaNubeInput)
        this.validateUrl(
          /https:\/\/(www\.)?[a-zA-Z0-9]{1,256}(\.com)\.ar$/,
          "tiendaNubeUrl",
          phrases.ERROR_URL_TIENDA_NUBE
        );

      this.validateUrl(
        /https:\/\/(www\.)?shopee(\.com)?\.(ar|br|mx|cl|co)\/.+$/,
        "storeUrl",
        phrases.ERROR_URL_SHOPEE
      );

      this.validateQuantityAdjustment();
      return this.errors.length !== 0 ? true : false;
    },
    validateUrl(expression, field, errorMessage) {
      if (!expression.test(this[field])) {
        this.errors.push(`- ${errorMessage}`);
      }
    },
    validateQuantityAdjustment() {
      if (this.quantityPercentageAdjustmentFactor > 100.0) {
        this.errors.push(
          `- ${this.quantityAdjustmentConfig.texts.PERCENTAGE_ERROR}`
        );
      }
    },
    submitFileForConversion() {
      const data = this.createFormData();
      ApiService.post(this.convertFileURL, data)
        .then((res) => {
          this.saveGroupId(res.data.group_id);
          this.buttonLoading = { "is-loading": false };
          this.showProgressBar = true;
          this.forceComponentsReRender();
        })
        .catch((err) => {
          const errorMessage = getErrorMessage(err.response);
          this.errors.push(`- ${errorMessage}`);
          this.showModal();
          this.showProgressBar = false;
          this.buttonLoading = { "is-loading": false };
          this.fieldDisabled = false;
        });
    },
    createFormData() {
      const data = new FormData();

      data.append("shopee_store_url", this.storeUrl);
      data.append("value_adjustment_factor", this.valueAdjustmentFactor);
      data.append(
        "percentage_adjustment_factor",
        this.percentageAdjustmentFactor
      );
      data.append("file_to_convert", this.getFile);

      if (this.channels.length > 0)
        data.append("logistic_channels", this.getLogisticChannelsData());

      if (this.quantityPercentageAdjustmentFactor)
        data.append(
          "quantity_percentage_adjustment_factor",
          this.quantityPercentageAdjustmentFactor
        );

      if (this.showTiendaNubeInput)
        data.append("tienda_nube_store_url", this.tiendaNubeUrl);

      return data;
    },
    getLogisticChannelsData() {
      return JSON.stringify({ [this.selectedChannel]: true });
    },
    saveGroupId(groupId) {
      sessionStorage.groupId = groupId;
    },
    loadGroupId() {
      return sessionStorage.groupId;
    },
    showModal() {
      this.isModalVisible = true;
    },
    showDisclaimerModal() {
      this.isDisclaimerModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.errors = [];
    },
    closeDisclaimerModal() {
      this.isDisclaimerModalVisible = false;
    },
    acceptDisclaimerModal() {
      this.isDisclaimerModalVisible = false;
      this.sendFile();
    },
    downloadShopeeExcel() {
      this.downloadButtonDisabled = true;
      this.buttonLoading = { "is-loading": true };
      ApiService.post(
        `/download-converted-file/${this.loadGroupId()}`,
        {},
        { responseType: "blob" }
      )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `converted_${res.headers["x-content-filename"]}`
          );
          document.body.appendChild(link);
          link.click();
          this.buttonLoading = { "is-loading": false };
          this.downloadButtonDisabled = false;
          this.fieldDisabled = false;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style>
.button-container {
  margin-top: 1.75rem;
}
</style>
