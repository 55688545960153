<template>
  <div class="block">
    <div class="columns">
      <div class="column">
        <span class="instructions-icon">
          <i class="fas fa-file-download"></i>
        </span>
        <div class="content">
          {{ texts.STEP_ONE.PART_ONE }}
          <a :href="this.instructionsVideoURL" target="_blank">
            <b>{{ texts.STEP_ONE.PART_TWO }}</b>
          </a>
          {{ texts.STEP_ONE.PART_THREE }}
        </div>
      </div>
      <div class="column">
        <span class="instructions-icon">
          <i class="fas fa-sync"></i>
        </span>
        <div class="content" style="width: 75%; margin: 0 auto">
          {{ texts.STEP_TWO }}
        </div>
      </div>
      <div class="column">
        <span class="instructions-icon">
          <i class="fas fa-file-upload"></i>
        </span>
        <div class="content">
          {{ texts.STEP_THREE.PART_ONE }}
          <a :href="this.categoriesTreeURL" target="_blank">{{
            texts.STEP_THREE.PART_TWO
          }}</a>
          {{ texts.STEP_THREE.PART_THREE }}
          <br />
          <br />
          <p>
            {{ texts.STEP_THREE.PART_FOUR }}
            <a :href="this.shopeeUploadInstructionsVideoURL" target="_blank">{{
              texts.STEP_THREE.PART_FIVE
            }}</a>
            {{ texts.STEP_THREE.PART_SIX }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import environment from "@/environment.js";

export default {
  data() {
    return {
      instructionsVideoURL: environment.instructionsVideoURL,
      categoriesTreeURL: environment.categoriesTreeURL,
      shopeeUploadInstructionsVideoURL:
        environment.shopeeUploadInstructionsVideoURL,
      texts: environment.language.COMPONENTS.INSTRUCTIONS,
    };
  },
};
</script>
<style>
.instructions-icon {
  font-size: 2rem;
}

.content {
  text-align: justify;
}

.block {
  margin: 0 2rem;
}
</style>
