import environment from "@/environment";

const texts = environment.language.COMPONENTS.PRICE_ADJUSTMENT;

const percentageAdjustmentConfig = {
  name: "percentageAdjustment",
  criteria: {
    increaseText: texts.PORCENT_INCREASE_TEXT,
    reduceText: texts.PORCENT_REDUCE_TEXT,
  },
  icon: {
    name: "fas fa-percentage",
    class: "icon is-small is-right",
    controlIconPosition: "has-icons-right",
  },
  placeholder: texts.PORCENT_PLACEHOLDER,
  checkboxText: texts.PORCENT_CHECKBOX,
  tooltipText: texts.PORCENT_DESCRIBE,
};

export default percentageAdjustmentConfig;
