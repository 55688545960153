export default {
  state: {
    categoryFile: "",
  },

  getters: {
    getFile: (state) => state.categoryFile,
  },

  mutations: {
    ADD_SKUS_FILE: (state, categoryFile) => (state.categoryFile = categoryFile),
  },

  actions: {
    ADD_SKUS_FILE: ({ commit }, file) => commit("ADD_SKUS_FILE", file),
  },
};
