<template>
  <div class="field has-addons">
    <div class="control">
      <a class="button is-static" for="storeUrl">{{ label }}</a>
    </div>
    <div class="control is-expanded">
      <input
        :disabled="fieldDisabled"
        class="input is-normal"
        @input="change"
        v-model.trim="localStoreUrl"
        type="url"
        required="required"
        id="storeUrl"
        placeholder="https://"
      />
    </div>
  </div>
</template>

<script>
import environment from "@/environment";

export default {
  name: "StoreUrl",
  props: ["storeUrl", "fieldDisabled", "label"],
  data() {
    return {
      localStoreUrl: null,
      texts: environment.language.COMPONENTS.STORE,
    };
  },
  methods: {
    change() {
      this.$emit("update:storeUrl", this.localStoreUrl);
    },
  },
};
</script>
