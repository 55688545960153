import environment from "@/environment";

const findTexts = () => {
  return environment.language.COMPONENTS.QUANTITY_ADJUSTMENT;
};

const findEnabled = () => {
  const texts = findTexts();

  return !!texts;
};

const quantityAdjustmentConfig = {
  texts: findTexts(),
  enabled: findEnabled(),
  icon: {
    name: "fas fa-percentage",
    class: "icon is-small is-right",
    controlIconPosition: "has-icons-right",
  },
  name: "quantityPercentageAdjustmentFactor",
};

export default quantityAdjustmentConfig;
