<template>
  <div>
    <p class="content">{{ texts.CONTENT_PART_ONE }}</p>
    <p class="content">
      {{ texts.CONTENT_PART_TWO }}
      <a
        href="https://help.shopee.com.br/br/s/article/Termos-de-Servi%C3%A7o"
        target="_blank"
        >{{ texts.SERVICE_TERMS }}</a
      >,
      <a
        href="https://help.shopee.com.br/br/s/article/pol%C3%ADticas-de-privacidade"
        target="_blank"
        >{{ texts.PRIVACY_POLICIES }}</a
      >,
      <a
        href="https://help.shopee.com.br/s/article/Produtos-proibidos"
        target="_blank"
        >{{ texts.PRODUCT_POLICIES }}</a
      >
      {{ texts.CONTENT_PART_THREE }}
    </p>

    <label class="label">
      <input type="checkbox" v-model="localCheck" @click="checked" />
      {{ texts.CHECKBOX }}
    </label>
  </div>
</template>
<script>
import environment from "@/environment";

export default {
  props: ["disclaimerCheck"],
  data() {
    return {
      texts: environment.language.COMPONENTS.DISCLAIMER,
      localCheck: false,
    };
  },
  methods: {
    checked() {
      this.$emit("update:disclaimerCheck", !this.localCheck);
    },
  },
};
</script>
