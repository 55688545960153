import Vue from "vue";
import Vuex from "vuex";
import messages from "./modules/messages";
import client_data from "./modules/client_data";

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== "uat";

export default new Vuex.Store({
  modules: {
    client_data,
    messages,
  },
});
