<template>
  <div class="block mx-0 has-text-left">
    <h6 v-if="loading">Carregando...</h6>

    <section v-else>
      <h6 class="is-size-4">{{ title }}</h6>
      <p class="is-size-6 mb-5">
        {{ subtitle.SUBTITLE.PART_ONE }}
        <a :href="subtitle.HELP_URL" target="_blank">
          {{ subtitle.SUBTITLE.PART_TWO }}
        </a>
        {{ subtitle.SUBTITLE.PART_THREE }}
      </p>

      <div class="control">
        <div v-for="option in options" :key="option.name">
          <label class="radio">
            <input
              type="radio"
              :value="option.value"
              v-model="selectedChannel"
            />
            {{ option.name }}
          </label>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.switch[type="checkbox"].is-danger:checked + label::before {
  background: #ee4d2d;
}
</style>

<script>
import ApiService from "@/services/apiService.js";
import environment from "@/environment";

export default {
  name: "LogisticChannels",
  props: ["value", "options"],

  data: () => ({
    loading: false,
  }),

  computed: {
    selectedChannel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    title: () => environment.language.COMPONENTS.LOGISTIC_CHANNELS.TITLE,
    subtitle: () => environment.language.COMPONENTS.LOGISTIC_CHANNELS,
  },

  mounted: function () {
    ApiService.get("/logistics/channels/BR")
      .then(({ data }) => {
        const channels = Object.keys(data).map((channel) => ({
          name: channel,
          value: data[channel],
          use: false,
        }));

        channels.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        this.$emit("loaded", channels);
      })
      .finally(() => (this.loading = false));
  },
};
</script>
